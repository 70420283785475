import Box from '@material-ui/core/Box'
import Grid, { GridJustification } from '@material-ui/core/Grid'
import { Headline2, Paragraph1 } from '@/shared/components/Typography'
import { ISectionComponentProps } from 'components/Sections/utils'
import ButtonLink from '@/shared/components/Button/ButtonLink'
import parse from 'html-react-parser';
import { useLinkStyles } from '../styles'
import Tag from '@/shared/components/Tag/Tag'

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
const linkIconPath = `${iconPath}/website-builder/16px black/Link.svg`

export default function LinkDefault({ section, isPreview = false }: ISectionComponentProps) {
    const classes = useLinkStyles()
    const { name, content, customize } = section
    const { paragraph, button_text, button_link, open_in_new_window } = content
    const { alignment } = customize.format

    const justifyContent = {
        left: 'flex-start',
        center: 'center'
    }[alignment] as GridJustification

    return (
        <Box>
            <Box justifyContent={alignment} width='100%' display='flex'>
                <Tag imgSrc={linkIconPath} size='large' />
            </Box>

            <Box height='100%' display='flex' alignItems='center' textAlign={alignment}>
                <Grid container spacing={4}>
                    <Grid xs={12} item>
                        <Headline2>{name}</Headline2>
                    </Grid>

                    {paragraph && (
                        <Grid xs={12} item className={classes.preLine}>
                            <Paragraph1>{parse(paragraph)}</Paragraph1>
                        </Grid>
                    )}

                    <Grid xs={12} item container justifyContent={justifyContent}>
                        <ButtonLink href={button_link} openInNewWindow={open_in_new_window || isPreview}>
                            {button_text}
                        </ButtonLink>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
